import * as React from "react"
import { Link } from "gatsby"
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';


const ListItem = ({icon, title, link, createdAt}) => {
  return (
    <article className="ArticleCard_container">
      <Link style={{ textDecoration: 'none' }} to={link} itemProp="url" className="ArticleCard_mainLink">
      <Paper sx={{ maxWidth: 800, my: 1, mx: 'auto', p: 2 }}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item>
            <Avatar sx={{ width: 100, height: 100 , bgcolor: '#edebe6' }}><Typography variant="h4">{icon}</Typography></Avatar>
          </Grid>
          <Grid item xs>
            <Typography sx={{fontWeight: 'bold'}} variant="h6" mt={1}>{title}</Typography>
            <Typography variant="h8">🕣 {createdAt}</Typography>
          </Grid>
        </Grid>
      </Paper>
      </Link>
    </article>
  )
}

export default ListItem