import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Typography from '@mui/material/Typography';


const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType=""
      >
        <header>
          <div className="global-wrapper">
          <Typography variant="h3" sx={{textAlign: 'center'}}>{post.frontmatter.Icon}</Typography>
          <Typography variant="h5" sx={{fontWeight: 'bold', textAlign: 'center'}}>{post.frontmatter.title}</Typography>
          <Typography variant="subtitle1" sx={{textAlign: 'center'}}>{post.frontmatter.CreatedAt.start.replaceAll('/', '.')}</Typography>
          </div>
        </header>
        <hr />
        <div className="content-wrapper">
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        </div>
        <hr />
        {/* <Bio /> */}
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={`/blog/${previous.frontmatter.Slug}`} rel="prev">
                ← {previous.frontmatter.Icon} {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/blog/${next.frontmatter.Slug}`} rel="next">
                {next.frontmatter.Icon} {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: {eq: $id}) {
      id
      html
      excerpt
      frontmatter {
        title
        CreatedAt {
          start(formatString: "YYYY/MM/DD")
        }
        Slug
        Icon
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      frontmatter {
        title
        Slug
        Icon
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      frontmatter {
        title
        Slug
        Icon
      }
    }
  }
`